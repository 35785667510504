@import-normalize;

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

img {
  vertical-align: middle;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body, #root {

}

#root {
  display: flex;
  flex-direction: column;
}

    ul {
        padding: 0;


    }

    ul li {
        list-style-type: none;
    }

    ul li a {
        color: white;
        text-decoration: none;
    }

    ul li a:hover {
        color: white;
        text-decoration: underline;
    }

    .tooltip {
        background-color: black !important;
    }

@font-face {
    font-family: "DIN-BoldAlternate";

    src: url(/static/media/DINAlternateBold.6ddb880f.woff) format("woff"), 
    url(/static/media/DINAlternateBold.46e326d3.woff2) format("woff2"); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}

