    ul {
        padding: 0;


    }

    ul li {
        list-style-type: none;
    }

    ul li a {
        color: white;
        text-decoration: none;
    }

    ul li a:hover {
        color: white;
        text-decoration: underline;
    }

    .tooltip {
        background-color: black !important;
    }
